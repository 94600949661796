export function match<
  Key extends PropertyKey,
  ReturnValue,
  Branch extends () => ReturnValue,
  Options extends Record<Key, Branch>,
  Variant extends keyof Options
>(options: Options, variant: Variant): ReturnType<Options[Variant]>;

export function match(
  options: Record<PropertyKey, () => unknown>,
  variant: PropertyKey
) {
  if (!options[variant]) {
    return options["null"]();
  } else {
    return options[variant]();
  }
}
